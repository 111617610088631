(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/base/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/tipsen/base/assets/javascripts/init.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const {
  render
} = ReactDOM;
const {
  Provider
} = ReactRedux;
const {
  EngineProvider
} = svs.components.tipsen.engine;
const {
  BrowserRouter,
  MemoryRouter
} = ReactRouterDOM;
const {
  baseUrl
} = svs.core.data;
const {
  Tipsen
} = svs.components.tipsen.base;
const {
  makeStore
} = svs.components.tipsen.store;
const {
  ModalPortal
} = svs.components.lbUi.modal;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const App = _ref => {
  let {
    store,
    routerProps
  } = _ref;
  const Router = routerProps ? MemoryRouter : BrowserRouter;
  return React.createElement(React.StrictMode, null, React.createElement(ErrorBoundary, null, React.createElement(Router, _extends({
    basename: baseUrl
  }, routerProps), React.createElement(Provider, {
    store: store
  }, React.createElement(EngineProvider, null, React.createElement(ModalPortal, null, React.createElement(Tipsen, null)))))));
};
const init = appRoot => {
  if (appRoot) {
    const {
      preloadedState
    } = svs.tipsen.data;
    const store = makeStore({
      preloadedState,
      lsKey: 'svs.tipsen'
    });
    svs.components.tipsen.competition.competitionFactoryInstance.getCompetitionFactoryInstance(store);
    render(React.createElement(App, {
      store: store
    }), appRoot);
  }
};
setGlobal('svs.components.tipsen.base', {
  init,
  App
});

 })(window);