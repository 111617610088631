(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/base/assets/javascripts/tipsen.js') >= 0) return;  svs.modules.push('/components/tipsen/base/assets/javascripts/tipsen.js');
"use strict";

const {
  Route,
  Switch
} = ReactRouterDOM;
const {
  useMemo
} = React;
const {
  data: coreData
} = svs.core;
const {
  ModalHidden
} = svs.components.lbUi.modal;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  TipsenNav,
  RoutePlayFallback,
  RouteResultFallback,
  HandlePath
} = svs.components.tipsen.modularBase;
const {
  tipsenLazy
} = svs.components.tipsen.tipsenLazy;
const {
  selectDisplayName
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useSelector
} = ReactRedux;
const LazyResult = tipsenLazy('/cl/tipsen/route-result', () => svs.components.tipsen.routeResult.RouteResult, {
  fallback: RouteResultFallback
});
const LazySpela = tipsenLazy('/cl/tipsen/route-play', () => svs.components.tipsen.routePlay.RoutePlay, {
  fallback: RoutePlayFallback
});
const LazyRouteCompetition = tipsenLazy('/cl/tipsen/route-competition', () => svs.components.tipsen.routeCompetition.RouteCompetition);
const {
  MyBetsRoute
} = svs.components.tipsen.base;
const Tipsen = () => {
  const displayName = useSelector(state => selectDisplayName(state));
  const handlePathData = useMemo(() => ({
    productName: displayName,
    cmsTitle: coreData.cmsTitle
  }), [displayName]);
  return React.createElement(React.Fragment, null, React.createElement(HandlePath, {
    data: handlePathData
  }), React.createElement(ModalHidden, null, React.createElement(TipsenNav, null), React.createElement(Switch, null, React.createElement(Route, {
    component: LazyResult,
    path: "/resultat/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?"
  }), React.createElement(Route, {
    component: MyBetsRoute,
    path: "/".concat(TipsenModuleGameUrl.MY_BETS)
  }), React.createElement(Route, {
    component: LazyRouteCompetition,
    path: "/tavlingar/:competitionName"
  }), React.createElement(Route, {
    component: LazySpela
  }))));
};
setGlobal('svs.components.tipsen.base.Tipsen', Tipsen);

 })(window);