(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/base/assets/javascripts/my-bets-route.js') >= 0) return;  svs.modules.push('/components/tipsen/base/assets/javascripts/my-bets-route.js');
"use strict";

const {
  ProtectedRoute
} = svs.components.lbUtils.protectedRoute;
const {
  Route,
  Switch
} = ReactRouterDOM;
const {
  tipsenLazy
} = svs.components.tipsen.tipsenLazy;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  RouteWagerListFallback,
  RouteWagerFallback
} = svs.components.tipsen.modularBase;
const LazyWagerList = tipsenLazy('/cl/tipsen/route-wager-list', () => svs.components.tipsen.routeWagerList.PlayerWagersLists, {
  fallback: RouteWagerListFallback
});
const LazyWager = tipsenLazy('/cl/tipsen/route-wager', () => svs.components.tipsen.routeWager.RouteWager, {
  fallback: RouteWagerFallback
});
const roles = ['Player', 'CustomerService'];
const MyBetsRoute = () => React.createElement(ProtectedRoute, {
  roles: roles
}, React.createElement(Switch, null, React.createElement(Route, {
  component: LazyWager,
  path: "/".concat(TipsenModuleGameUrl.MY_BETS, "/:wagerId(\\d+)")
}), React.createElement(Route, {
  component: LazyWagerList
})));
setGlobal('svs.components.tipsen.base.MyBetsRoute', MyBetsRoute);

 })(window);